.dataTables_length.bs-select{
    display: none !important;
}

div[data-test="datatable-search"] div{
    /* display: none !important; */
    border:none;
    background: white;
    padding: 0px;
}

div[data-test="datatable-input"]{
    background:transparent !important;
}

div[data-test="datatable"]{
    background: #C1C1C115;
    padding:20px;
}

tbody[data-test="table-body"]{
    color:#707070 !important;
    font-size:16px;
}

thead[data-test="table-foot"]{
    display: none !important;
}

thead{
    color: #C1C2C6;
    font-size: 16px;
}

a[data-test="page-link"]{
    background: #883f7c !important;
    color: white !important;
    border-radius: 2px !important;
}
ul[data-test="pagination"]{
    border: none;
    padding: 0px;
    justify-content: end;
}

