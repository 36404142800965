.btn-text.disabled{
    border:none !important;
}

.react-switch-bg{
    border: 1px solid #C1C2C6;

}

.accordion-button:not(.collapsed){
    background-color: #ebedef;
    box-shadow: inset 0 -1px 0 rgba(0,0,0,.13);
    color: #000;
}
.accordion-button:not(.collapsed)::after {
    color: #141414;
    -webkit-filter: brightness(0);
    filter: brightness(0);
  }