.application-table{
    background: #C1C1C115;
    padding:20px !important;
}

thead{
    color: #C1C2C6;
    font-size: 14px;
}

tbody{
    color:#707070 !important;
    font-size:14px;
}