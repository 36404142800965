#dropdown-basic-button {
    background: transparent;
    color: white;
    border: 0px;
    font-size: 24px;
    font-weight: 700 !important;
}
#dropdown-basic-button:focus {
    outline: 0px solid;
    box-shadow: none;
}