.create-quotation-screen input:not([type='radio']){
    border-radius: 2px !important;
      background-color: white !important;
      border-color: #E5E5E5 !important;
}

.create-quotation-screen .form-check-input:checked{
    background-color: #BEC663;
    border-color: #BEC663;
  
}
.create-quotation-screen .form-check-input:focus{
    box-shadow:none;
}
