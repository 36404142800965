.form-card{
    min-width:600px;
    margin-left:200px;
    padding:90px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.green-side{
    width:500px;
}

.submit-btn{
    margin-top:50px !important;
    color:#fff !important;
    background:#BEC663  !important;
    border-radius: 2px !important;
    padding:6px 60px  !important;
    font-size: 18px !important;
}
::placeholder {
    color: #C1C2C6 !important;
    opacity: 1 !important; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #C1C2C6 !important;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
   color: #C1C2C6 !important;
  }


  @media all and (max-width: 990px) {
    .green-side {
      display: none !important;
    }
    .form-card{
        min-width:unset;
        margin-left:unset;
        padding:40px;
    }
    .flex-wrapper{
        justify-content:center
    }
    .submit-btn{
        width: 100%;
    }
    h2{
        font-size: 30px !important;
    }
  }

