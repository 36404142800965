.nav-link.payment, .payment.nav-link:active{
border-radius:100px !important;
background: #efefef !important;
margin-right: 10px;
color: black;
font-weight:bold;
}
.nav-link.payment:hover{
    color: black;
    background: #dddddd !important;
}
.nav-link.payment.active.payment{
    background:#883F7C !important;
    color:white;
    border:none;
    font-weight:bold;
}

.payment-option{
    border-radius: 10px;
}

.payment-option:hover{
    background:#efefef;
    cursor: pointer;
}

.payment-option.selected{
    background:#ffe9b8;
}