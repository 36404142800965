.review .nav-link.payment,
.payment.nav-link:active {
    border-radius: 100px !important;
    background: #efefef !important;
    margin-right: 10px;
    color: #883F7C;
    font-weight: bold;
}

.review .nav-link.payment:hover {
    color: #883F7C;
    background: #dddddd !important;
}

.review .nav-link.payment.active.payment {
    background: #883F7C !important;
    color: white;
    border: none;
    font-weight: bold;
}

.review .payment-option {
    border-radius: 10px;
    background: white;
}

.review .payment-option:hover {
    background: #efefef;
    cursor: pointer;
}

.review .payment-option.selected {
    background: #f1f6bc;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #fff;
    background-color: #883F7C;
    border: 1px solid #883F7C;
}

.nav-tabs .nav-link {
    background: #eee;
    color: #777;
}